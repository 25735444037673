import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import logo from "../images/logo-woek-amsterdam.png";
import ammehula from "../images/Ammehula.png";
import {Link} from "gatsby";

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found"/>
        <div id="wrapper">
            <div id="about" className="page">

                <div className="content">
                    <Link to={"/"}>
                        <div className="backButton">
                            <div className="arrow"/>
                            <img src={ammehula} alt="No hullahooping allowed"/>
                        </div>
                    </Link>
                    <h1>NOT FOUND :(</h1>
                    <p>You just hit a route that doesn&#39;t exist. Sad.</p>
                    <p>
                        <img src={logo} alt="logo Woek" style={{width: "100%", maxWidth: 200, marginTop: 30,}}/>
                    </p>

                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
